<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1 class="mb-0">Contract Manage</h1>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12">
        <validation-observer ref="contractFormRef" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information" active>
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Contract No."
                        label-for="v-contract_no"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.refnum"
                          id="v-contract_no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Contract Type"
                        label-for="v-contract_type"
                      >
                        <b-form-select
                          v-model="formData.contract_type"
                          :options="contractTypes"
                          id="v-contract_type"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Start Date" label-for="v-start_date">
                        <b-form-input
                          id="v-start_date"
                          v-model="previewStartDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="End Date" label-for="v-end_date">
                        <b-form-input
                          id="v-end_date"
                          v-model="previewEndDate"
                          type="date"
                          class="form-control"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Chargeable Token"
                        label-for="v-chargeable_type"
                      >
                        <b-form-select
                          id="v-chargeable_type"
                          v-model="formData.chargeable_type"
                          :options="[
                            { text: 'Hours', value: 'H' },
                            { text: 'Token', value: 'T' },
                          ]"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group
                        label="Contract Eligible"
                        label-for="v-contract_eligible"
                      >
                        <b-input-group :append="chargeableType">
                          <b-form-input
                            id="v-contract_eligible"
                            v-model.number="formData.contract_eligible"
                            class="form-control text-right"
                          />
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group
                        label="Contract Status"
                        label-for="v-contract_status"
                      >
                        <b-form-select
                          id="v-contract_status"
                          v-model="formData.contract_status"
                          :options="contractStatuses"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Description"
                        label-for="v-description"
                      >
                        <b-form-textarea
                          id="v-description"
                          v-model="formData.description"
                          class="form-control"
                        ></b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12">
                      <b-form-group label="Details" label-for="v-details">
                        <quill-editor
                          ref="quillEditorRef"
                          v-model="content"
                          id="v-details"
                          :options="{ theme: 'snow' }"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Customer Information</h2>
                    </b-col>

                    <b-col class="text-right">
                      <CompanySearch @on-select="(v) => onSelectCompany(v)" />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Company Name"
                        label-for="v-ticket-no"
                      >
                        <b-form-input
                          v-model="formData.company_name"
                          id="v-ticket-no"
                          disabled
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Address</h2>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group
                        label="Building/Block/House No and Street Name"
                        label-for="v-addr1"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.addr1"
                          id="v-addr1"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Unit # and Building Name"
                        label-for="v-addr2"
                      >
                        <b-form-input
                          disabled
                          v-model="formData.addr2"
                          id="v-addr2"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Country" label-for="v-country">
                        <v-select
                          disabled
                          v-model="formData.country"
                          :options="
                            $store.getters['hbaseGeneral/getAllCountries']
                          "
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Postal" label-for="v-postal">
                        <b-form-input
                          disabled
                          v-model="formData.postal"
                          id="v-postal"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Contact Person</h2>
                    </b-col>

                    <b-col class="text-right" v-if="formData.company_id">
                      <b-button
                        size="sm"
                        variant="outline-primary"
                        @click="addModal = true"
                      >
                        Add contact person
                      </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Contact Person" label-for="v-poc">
                        <v-select
                          id="v-poc"
                          v-model="formData.poc"
                          :options="contacts"
                          :reduce="(contacts) => contacts.value"
                          @option:selected="onSelectCompanyContact($event)"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group
                        label="Designation"
                        label-for="v-designation"
                      >
                        <b-form-select
                          id="v-designation"
                          v-model="formData.designation"
                          :options="designations"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="12" md="3">
                      <b-form-group label-for="v-email">
                        <template v-slot:label>
                          Email <span class="text-danger">*</span>
                        </template>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="required|email"
                        >
                          <b-form-input
                            v-model="formData.email"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Telephone" label-for="v-tel">
                        <b-form-input v-model="formData.tel" id="v-tel" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Mobile" label-for="v-mobile">
                        <b-form-input v-model="formData.mobile" id="v-mobile" />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                      <b-form-group label="Fax" label-for="v-fax">
                        <b-form-input v-model="formData.fax" id="v-fax" />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>GST and Currency</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col cols="12" md="4">
                      <b-form-group label="GST" label-for="v-ticket-no">
                        <b-form-select
                          @change="onSelectGST($event)"
                          v-model="formData.gst"
                          :options="gsts"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="2">
                      <b-form-group label="GST Percent" label-for="v-ticket-no">
                        <b-form-input
                          type="number"
                          v-model="formData.gst_percent"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                      <b-form-group label="Currency" label-for="v-ticket-no">
                        <b-form-select
                          v-model="formData.currency_id"
                          :options="currencies"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col>
                    <!-- <b-col cols="12" md="2">
                      <b-form-group
                        label="Exchange Rate"
                        label-for="v-ticket-no"
                      >
                        <b-form-input
                          type="number"
                          v-model.number="formData.exchange_rate"
                          id="v-ticket-no"
                        />
                      </b-form-group>
                    </b-col> -->
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Other</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group label="Upload File">
                        <b-form-file
                          v-model="fileUpload"
                          placeholder="Choose a file..."
                          id="fileUpload"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="!formData.base64">
                    <b-col>
                      <b-form-group>
                        FILE:
                        <span
                          class="text-primary cursor-pointer"
                          @click="onViewThumbnail"
                        >
                          {{ formData.filename }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="formData.base64">
                    <b-col class="text-center">
                      <b-form-group>
                        <div class="">
                          <b-img
                            v-if="fileType === 'image'"
                            height="250"
                            width="250"
                            thumbnail
                            fluid
                            :src="onViewThumbnail()"
                          />
                          <b-img
                            v-if="fileType === 'application'"
                            height="150"
                            width="150"
                            thumbnail
                            fluid
                            :src="require('@/assets/document.png')"
                          />
                          <video
                            height="250"
                            width="250"
                            controls
                            v-if="fileType === 'video'"
                          >
                            <source :src="onViewThumbnail()" />
                          </video>
                        </div>
                        <span>
                          {{ formData.filename }}
                        </span>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Add Products</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <ContractCoverages
                          :data-source="dataSourceContractCoverages"
                          :formData="formData"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <h2>Products and Services Covered</h2>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col>
                      <b-form-group>
                        <ContractServices
                          :data-source="dataSourceContractServices"
                          :formData="formData"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        @click="goBack()"
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                      >
                        Back
                      </b-button>
                      <b-button
                        :disabled="invalid"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>

              <span v-if="formData.id">
                <b-tab title="Notes">
                  <Notes
                    ref="notesRef"
                    module="contracts"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Documents">
                  <Documents
                    ref="documentsRef"
                    module="contracts"
                    :header-id="parseInt(formData.id)"
                  />
                </b-tab>
                <b-tab title="Comments"> </b-tab>
                <b-tab title="Logs"> </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Company Contact"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <Alert :error="errors" />

      <b-row>
        <b-col>
          <h2>Contact</h2>
        </b-col>
      </b-row>

      <validation-observer ref="companyContactRef">
        <b-row>
          <b-col>
            <b-form-group label="Title" label-for="v-title">
              <b-form-select
                id="v-title"
                v-model="formDetail.title"
                :options="courtesyTitles"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Firstname" label-for="v-first_name">
              <b-form-input v-model="formDetail.first_name" id="v-first_name" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Lastname" label-for="v-last_name">
              <b-form-input v-model="formDetail.last_name" id="v-last_name" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Desgination" label-for="v-designation">
              <b-form-select
                v-model="formDetail.designation"
                :options="designations"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Email" label-for="v-email">
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="v-email"
                  v-model="formDetail.email"
                  name="v-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Mobile" label-for="v-mobile">
              <b-form-input v-model="formDetail.mobile" id="v-mobile" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Telephone" label-for="v-tel">
              <b-form-input v-model="formDetail.tel" id="v-tel" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h2>Account</h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-username">
              <b-form-input
                :disabled="disableField"
                v-model="formDetail.username"
                id="v-username"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="User Type" label-for="v-user_type">
              <b-form-select
                :disabled="disableField"
                v-model="formDetail.user_type"
                :options="userTypes"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <span v-if="!formDetail.id">
          <b-row>
            <b-col>
              <b-form-group label="Password" label-for="v-password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="v-password"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="v-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="v-password_confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  vid="confirm_password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="v-password_confirm"
                      v-model="confirm_password"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="v-password_confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </span>
      </validation-observer>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button
          :disabled="getValidation()"
          @click="onSubmitCompanyContacts"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </section>
</template>

<script>
import contractHeadersApi from '@api/contract_headers';
import companyContactsApi from '@api/company_contacts';
import gstsApi from '@api/gsts';
import currenciesApi from '@api/currencies';
import contractTypesApi from '@api/contract_types';
import designationsApi from '@api/designations';
import statusesApi from '@api/statuses';
// @todo change from utils classes to global-function
import {
  nowToLocaleDateString,
  UtilsDate,
  addToDate,
} from '@/utils/classes/dates';
import { queryParameters } from '@/schema';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { required, email } from '@validations';
import md5 from 'md5';

import ContractCoverages from './components/ContractCoverages.vue';
import ContractServices from './components/ContractServices.vue';

export default {
  name: 'ContractsManage',
  mixins: [togglePasswordVisibility],
  components: {
    ContractCoverages,
    ContractServices,
  },
  data: () => ({
    errors: null,
    required,
    email,
    activeTab: 0,
    dataSourceContractCoverages: [],
    dataSourceContractServices: [],
    previewStartDate: nowToLocaleDateString.split('T')[0],
    previewEndDate: addToDate(1, 'years').split('T')[0],
    content: '',
    formData: {
      id: 0,
      company_id: null,
      company_name: null,
      contract_type: null,
      contract_status: null,
      chargeable_type: 'H',
      contract_eligible: null,
      refnum: null,
      gst: null,
      gst_percent: null,
      currency_id: 'SGD',
      exchange_rate: null,
      description: null,
      details: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      tel: null,
      mobile: null,
      email: null,
      designation: null,
      poc: null,
      fax: null,
      status: 'O',

      filename: null,
      base64: null,
    },
    fileUpload: null,
    fileType: null,
    gsts: [],
    currencies: [],
    contractTypes: [],
    designations: [],
    contacts: [],
    addModal: false,
    designations: [],
    contractStatuses: [],
    courtesyTitles: ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.', 'Prof.'],
    userTypes: [
      { text: 'Customer Admin', value: 'custadmin' },
      { text: 'Customer User', value: 'custuser' },
    ],
    fields: [
      { caption: 'Username', field: 'username' },
      { caption: 'Staff Name', field: 'full_name' },
      { caption: 'Designation', field: 'designation' },
      { caption: 'User Type', field: 'preview_user_type' },
    ],
    formDetail: {
      id: 0,
      header_id: 0,
      username: null,
      password: null,
      user_id: null,
      user_type: 'custuser',
      email: null,
      title: null,
      first_name: null,
      last_name: null,
      mobile: null,
      tel: null,
      designation: 'custuser',
      status: 'O',
    },
    password: null,
    confirm_password: null,
    password1FieldType: 'password',
    password2FieldType: 'password',
  }),
  mounted() {
    this.loadSystemCode();
    this.loadDesignations();
    this.loadContractTypes();
    this.loadGSTs();
    this.loadCurrencies();

    if (this.$route.query.id) {
      this.loadContract();
    }
  },
  watch: {
    activeTab(v) {
      if (v === 1) {
        this.$refs.notesRef.loadNotes();
      } else if (v === 2) {
        this.$refs.documentsRef.loadDocuments();
      }
    },
    fileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const { name, type } = file;
          this.formData.filename = name;
          this.formData.base64 = reader.result;
          this.fileType = type.split('/')[0];
        };
      }
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.$route.query.id && !this.$route.query.duplicate
          ? 'Update'
          : 'Submit';
      },
    },
    chargeableType: {
      get() {
        return this.formData.chargeable_type === 'H' ? 'Hours' : 'Token';
      },
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    disableField: {
      get() {
        return Boolean(parseInt(this.formDetail.id));
      },
    },
  },
  methods: {
    loadGSTs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            const { description, percent } = item;
            const descriptionWithPercent = `${description} - ${percent}%`;
            items.push({
              value: item.name,
              text: descriptionWithPercent,
              percent,
              item,
            });
          }

          if (!this.formData.gst) {
            this.formData.gst = items[0].value;
            this.formData.gst_percent = items[0].percent;
          }

          this.gsts = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCurrencies() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      currenciesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ value: item.name, text: item.description, item });
          }

          this.currencies = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadContractTypes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      contractTypesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ value: item.name, text: item.description });
          }

          this.contractTypes = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadDesignations() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      designationsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ value: item.name, text: item.description });
          }

          this.designations = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCompanyContacts(id) {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header_id=${id}`,
      };

      companyContactsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          if (data.length > 0) {
            const [contact] = data;
            const { first_name, last_name, designation, email, tel, mobile } =
              contact;

            this.formData.poc = `${first_name} ${last_name}`;
            this.formData.designation = designation;
            this.formData.email = email;
            this.formData.tel = tel;
            this.formData.mobile = mobile;
          } else {
            this.formData.poc = null;
            this.formData.designation = null;
            this.formData.email = null;
            this.formData.tel = null;
            this.formData.mobile = null;
          }

          for (const item of data) {
            let fullName = item.first_name;

            if (item.last_name) {
              fullName += ` ${item.last_name}`;
            }

            items.push({
              label: fullName,
              value: fullName,
              item,
            });
          }

          this.contacts = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadContract() {
      contractHeadersApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const {
            start_date,
            end_date,
            contract_coverages,
            contract_services,
          } = data;
          const toDisplayDate = (date) => {
            return new UtilsDate(date).unixToInputDateTime().split('T')[0];
          };

          const { quill } = this.$refs.quillEditorRef;
          if (quill) {
            quill.root.innerHTML = data.details;
          }

          if (this.$route.query.duplicate) {
            delete data.id;
            data.refnum += '-1';
          }

          if (contract_coverages.data) {
            this.dataSourceContractCoverages = contract_coverages.data;
          }

          if (contract_services.data) {
            this.dataSourceContractServices = contract_services.data;
          }

          if (start_date) {
            this.previewStartDate = toDisplayDate(start_date);
          }

          if (end_date) {
            this.previewEndDate = toDisplayDate(end_date);
          }

          this.formData = data;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: '-created_at',
        page: { size: 1000 },
        status: 'O',
        filterExt: "code='contract:status'",
      };

      statusesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            const [cs] = data.filter((x) => {
              return x.code === 'contract:status';
            });
            if (cs) {
              this.contractStatuses = cs.selections;
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      const toUnix = (date) => {
        if (!date) {
          return new UtilsDate().dateToUnix();
        }

        return new UtilsDate(date).dateToUnix();
      };

      const getDetails = (dataSource, opt = { addOn: false }) => {
        const details = [];
        for (let i = 0; i < dataSource.length; i++) {
          const detail = dataSource[i];
          delete detail.id;

          detail.quantity = parseInt(detail.quantity);

          if (opt.addOn) {
            detail.start_date = toUnix(detail.start_date);
            detail.end_date = toUnix(detail.end_date);
          }

          details.push(detail);
        }

        return details;
      };

      this.$refs.contractFormRef.validate().then((success) => {
        if (success) {
          this.formData.start_date = toUnix(this.previewStartDate);
          this.formData.end_date = toUnix(this.previewEndDate);
          this.formData.details = this.content;
          this.formData.file = this.formData.filename;

          this.formData.coverages = getDetails(
            this.dataSourceContractCoverages,
            {
              addOn: true,
            }
          );
          this.formData.services = getDetails(this.dataSourceContractServices);

          let app;
          if (this.formData.id && !this.$route.query.duplicate) {
            app = contractHeadersApi.update(this.formData);
          } else {
            app = contractHeadersApi.add(this.formData);
          }

          if (app) {
            app
              .then(({ data }) => {
                this.toastConfig();
                const url = `/pages/agreements/contracts/view?id=${data.id}`;
                this.$router.push(url);
              })
              .catch(() => {
                this.toastConfig('', 'error');
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    onSelectGST(event) {
      const selectedGst = this.gsts.find((x) => {
        return x.item.name === event;
      });

      if (!selectedGst) {
        return;
      }

      this.formData.gst_percent = selectedGst.item.percent;
    },
    onSelectCompany(event) {
      if (event) {
        if (event.gst) {
          this.formData.gst = event.gst;
          this.onSelectGST(event.gst);
        }

        if (event.currency_id) {
          const currency = this.currencies.find((x) => {
            return +x.item.id === event.currency_id;
          });

          this.formData.currency_id = currency.value;
        }

        const {
          id,
          company_name,
          addr1,
          addr2,
          country,
          postal,
          company_contacts,
        } = event;

        this.formData.company_id = parseInt(id);
        this.formData.company_name = company_name;
        this.formData.addr1 = addr1;
        this.formData.addr2 = addr2;
        this.formData.country = country;
        this.formData.postal = postal;

        this.loadCompanyContacts(id);
      }
    },
    goBack() {
      this.$router.push('/pages/agreements/contracts');
    },
    onViewThumbnail() {
      if (this.formData.base64) {
        return this.formData.base64;
      } else {
        const params = {
          model: 'contracts',
          id: this.formData.id,
          thumbnail: this.formData.file,
        };

        return this.getImage(params);
      }
    },
    onSelectCompanyContact(event) {
      const { item } = event;
      if (item) {
        const { first_name, last_name, designation, email, tel, mobile } = item;

        this.formData.poc = `${first_name} ${last_name}`;
        this.formData.designation = designation;
        this.formData.email = email;
        this.formData.tel = tel;
        this.formData.mobile = mobile;
      } else {
        this.formData.poc = null;
        this.formData.designation = null;
        this.formData.email = null;
        this.formData.tel = null;
        this.formData.mobile = null;
      }
    },
    onSubmitCompanyContacts() {
      this.formDetail.header_id = this.formData.company_id;
      this.formDetail.password = this.password ? md5(this.password) : '';

      this.$refs.companyContactRef.validate().then((success) => {
        let app;

        if (success) {
          if (this.formDetail.id) {
            app = companyContactsApi.update(this.formDetail);
          } else {
            app = companyContactsApi.add(this.formDetail);
          }

          if (app) {
            app
              .then(({ data }) => {
                this.loadCompanyContacts(this.formData.company_id);
                this.closeModal();
              })
              .catch(({ errors }) => {
                if (errors) {
                  const { meta } = errors[0];
                  const email = meta ? meta['Email.Unique'] : '';
                  const username = meta ? meta['Username.Unique'] : '';
                  this.errors = {
                    response: {
                      data: { errors: [{ title: email ? email : username }] },
                    },
                  };
                }
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    closeModal() {
      this.addModal = false;
      this.password = null;
      this.confirm_password = null;

      const defaultForm = {
        id: 0,
        header_id: this.headerId,
        username: null,
        password: null,
        confirm_password: null,
        user_id: null,
        user_type: 'custuser',
        email: null,
        first_name: null,
        last_name: null,
        mobile: null,
        tel: null,
        designation: 'custuser',
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formDetail = Object.assign({}, defaultForm);
      });
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password';
    },
    getValidation() {
      const ref = this.$refs.companyContactRef;

      if (ref) {
        const { errors } = ref;
        if (errors) {
          const { confirm_password, email, password } = errors;
          if (
            (password && password.length > 0) ||
            (confirm_password && confirm_password.length > 0) ||
            (email && email.length > 0)
          ) {
            return true;
          }

          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
